/* eslint-disable */

export enum AppRoute {
  startingPage = '/',
  payfoneprefill = '/payfoneprefill',
  basicInfo = '/basicinfo',
  employerInfo = '/employer',
  residenceInfo = '/residence',
  referenceInfo = '/reference',
  reviewInfo = '/reviewinfo',
  ApprovalPage = '/ApprovalPage',
  identifyVerify = '/IdentityVerify',
  userInfo = '/userInfo',

  dofStartPage ='/dof/verification/:deId',
  indentitytab='/identity/:customerId/:deId',
  residenceTab ='/residence/:customerId/:deId',
  incomeTab ='/income/:customerId/:deId',
  reference ='/reference/:customerId/:deId',
  welcomepage ='/welcome/:customerId/:deId',
  dofAuthentication ='otp/auth/:customerId/:deId',
  reviewInformation ='/review/details/:customerId/:deId',
  dofSubmitted ='/dof/submission/:customerId/:deId'

}
