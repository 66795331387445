/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Grid, RACButton, Typography } from '@rentacenter/racstrap';
import React, { useEffect, useState, useRef } from 'react';
import { sendIntellicheckVerificationLink } from '../../../api/user';
import { useHistory } from 'react-router-dom';
import ScreenLoader from '../Common/ScreenLoader';
import { VerificationStyles } from '../../../styles/verificationStyles';

export const IdentityTab = () => {

  const classes: any = VerificationStyles();
  // PS_07: useState for iframe URL
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [verificationComplete, setVerificationComplete] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();


  // PS_09: useEffect to handle component load logic
  useEffect(() => {
    setLoader(true);
    // PS_10: Forming the payload
    async function sendLink() {
      const payload = {
        redirectUrl: window.sessionStorage.getItem('redirectUrl') || '',
        globalCustomerId: "cust-0892b27c-52ef-4619-8222-e499d1405123",
        businessUnit: "rac",
        dateOfBirth: "1982-10-01",
        firstName: "Jack",
        lastName: "Black",
        addressLine1: "123 Main St",
        city: "Chicago",  
        provinceCode: "IL",
        postalCode: "60060",
        countryCode: "US",
        phoneNumber: "",
        requestedDocumentSides: ["front", "selfie"],
        idDocument: {
            type: "na_dl",
            number: "9360197582",
            provinceCode: "IL"
        }
    }
  
      // PS_11: Calling sendIntellicheckVerificationLink function and setting iframe URL
      const response = await sendIntellicheckVerificationLink(payload);

      // eslint-disable-next-line no-console
      console.log('response from send', response);
      setIframeUrl(response?.value?.capture_url);
      setLoader(false);
    }
    sendLink()
  }, []);

  // PS_28: Handle iframe load and redirect detection
  const handleIframeLoad = () => {
    // eslint-disable-next-line no-console
    console.log('Iframe Reference details', iframeRef);
    if (iframeRef.current?.contentWindow?.location.href?.includes('https://www.google.com')) {
      // eslint-disable-next-line no-console
      console.log('Inside the completed Iframe', iframeRef.current?.contentWindow?.location.href);
      handleVerificationComplete();
    }
  };

  // PS_29: Handle verification complete
  const handleVerificationComplete = () => {
    // eslint-disable-next-line no-console
    console.log('Inside the handleVerificationComplete');
    setIframeUrl(null);
    setVerificationComplete(true);
  };

  // PS_31: Handle next button click
  const handleNext = () => {
    history.push('/residence');
  };

  // verificationComplete || true &&

  return (
    <Grid className={`${classes.tittleColor} ${classes.marginTop20p}`}>
      {loader && <ScreenLoader />}
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          onLoad={handleIframeLoad}
          ref={iframeRef}
          title="Identity Verification"
          width={'375'}
          height={'667'}
          allow={'camera'}
          frameBorder={'0'}
        />
      )}
      {verificationComplete && 
        <Grid>
          <Typography className={`${classes.tittleColor} ${classes.boldFont}`}>
            ID Verification Submitted
          </Typography>
          <Grid
            className={`${classes.p16px} ${classes.textAlignLeft} ${classes.pt0px}`}
          >
            <Typography
              className={`${classes.regularFont} ${classes.blackFont} ${classes.mt20px}`}
            >
              Your goverenment identity validation is complete. Please proceed
              to the next step to continue your verification.
            </Typography>
            <RACButton color="primary" variant="contained" className={`${classes.primaryButtonColor} ${classes.width100p} ${classes.mt20px}`} onClick={() => {handleNext}}>Next</RACButton>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};
