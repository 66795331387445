/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';

export const VerificationStyles = () => {
  const useClasses = makeStyles((theme) => ({
    tittleColor: {
        color: '#034694'
      },
    font16px: {
        fontSize: '16px'
    },
    boldFont: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(20),
    },
    marginTop10p: {
        marginTop: '10%'
    },
    marginTop20p: {
        marginTop: '20%'
    },
    regularFont: {
        fontFamily: 'OpenSans-regular',
        fontSize: theme.typography.pxToRem(14),
    },
    blackFont: {
        color: 'black'
    },
    mt20px: {
        marginTop: '20px'
    },
    p16px: {
        padding: '16px'
    },
    pt0px: {
        paddingTop: '0px'
    },
    textAlignLeft: {
        textAlign: 'left'
    },
    primaryButtonColor: {
        backgroundColor: '#0f99d6'
    },
    width100p: {
        width: '100%'
    }
  }))
return useClasses()
}