/* eslint-disable */
import { Redirect, Route, Switch } from 'react-router-dom';
import { useState } from 'react';

import { AppRoute } from '../config/route-config';
import PayfonePrefill from '../components/PayfonePrefill';
import BasicInfo from '../components/BasicInfo';
import EmployerInfo from '../components/EmployerInfo';
import ResidenceInfo from '../components/ResidenceInfo';
import ReferenceInfo from '../components/ReferenceInfo';
import ReviewInfo from '../components/ReviewInfo';
import { ApprovalPage } from '../components/ApprovalPage';
import IdentityVerify from '../components/IdentifyVerify';
import UserInfo from '../components/UserInfo';
import DofStartingPage from '../components/DofStartingPage';

// Customver Verification Optimization Component import starts

import { DOFHeader } from '../components/CustomerVerification/VerificationComponents/DOFHeader';
import { DOFSubmitted } from '../components/CustomerVerification/VerificationComponents/DOFSubmitted';
import { IdentityTab } from '../components/CustomerVerification/VerificationComponents/IdentityTab';
import { ResidenceTab } from '../components/CustomerVerification/VerificationComponents/ResidenceTab';
import { IncomeTab } from '../components/CustomerVerification/VerificationComponents/Income';
import { ReferenceTab } from '../components/CustomerVerification/VerificationComponents/ReferenceTab';
import { ReviewInformation } from '../components/CustomerVerification/VerificationComponents/ReviewInformation';
import { WelcomePage } from '../components/CustomerVerification/VerificationComponents/WelcomePage';
import { OTPAuthenticationTab } from '../components/CustomerVerification/VerificationComponents/OTPAuthentication';
import { DOFStartingPage } from '../components/CustomerVerification/VerificationComponents/DOFStartingPage';


export const routesTestId = 'routesTestId';


export const Routes = () => {
  const [customerVerificationOptimizationEnable, SetcustomerVerificationOptimizationEnable] = useState<boolean>(true)

  return (

    < div data-testid={routesTestId}>

      {customerVerificationOptimizationEnable ? <>
        <Switch>

          <Route
            exact
            path={AppRoute.dofStartPage}
            key="/dof/verification/:deId"
            component={DOFStartingPage}
          />
          <Route
            path={AppRoute.welcomepage}
            key="/welcome/:customerId/:deId"
            component={WelcomePage}
          />
          <Route
            path={AppRoute.indentitytab}
            key="/identity/:customerId/:deId"
            component={IdentityTab}
          />
          <Route
            path={AppRoute.residenceInfo}
            key="/residence/:customerId/:deId"
            component={ResidenceTab}
          />
          <Route
            path={AppRoute.incomeTab}
            key="/income/:customerId/:deId"
            component={IncomeTab}
          />
          <Route
            path={AppRoute.reference}
            key="/reference/:customerId/:deId"
            component={ReferenceTab}
          />
          <Route
            path={AppRoute.reviewInformation}
            key="/reviewInformation/:customerId?/:deId?"
            component={ReviewInformation}
          />
          <Route
            path={AppRoute.dofSubmitted}
            key="/dof/submission/:customerId/:deId"
            component={DOFSubmitted}
          />
          <Route
            path={AppRoute.dofAuthentication}
            key="/otp/auth/:customerId/:deId"
            component={OTPAuthenticationTab}
          />
        </Switch>


      </> :
        <Switch>
          <Route
            exact
            path={AppRoute.startingPage}
            key="/"
            component={DofStartingPage}
          />
          <Route
            exact
            path={AppRoute.payfoneprefill}
            key="/payfoneprefill"
            component={PayfonePrefill}
          />
          <Route
            exact
            path={AppRoute.basicInfo}
            key="/basicinfo"
            component={BasicInfo}
          />
          <Route
            exact
            path={AppRoute.employerInfo}
            key="/employerinfo"
            component={EmployerInfo}
          />
          <Route
            exact
            path={AppRoute.residenceInfo}
            key="/residenceinfo"
            component={ResidenceInfo}
          />
          <Route
            exact
            path={AppRoute.referenceInfo}
            key="/referenceinfo"
            component={ReferenceInfo}
          />
          <Route
            exact
            path={AppRoute.reviewInfo}
            key="/reviewInfo"
            component={ReviewInfo}
          />
          <Route
            exact
            path={AppRoute.ApprovalPage}
            key="/ApprovalPage"
            component={ApprovalPage}
          />
          <Route
            exact
            path={AppRoute.identifyVerify}
            key="/IdentityVerify"
            component={IdentityVerify}
          />
          <Route
            exact
            path={AppRoute.userInfo}
            key="/userInfo"
            component={UserInfo}
          />
        </Switch>
      }
    </div >
  )


}
